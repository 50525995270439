import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'


import Antd from "ant-design-vue";
import 'ant-design-vue/dist/antd.css';
import './assets/styles.css';

const store = createStore({
  state () {
    let  thumb_url = "https://assets.smileandlearn.com/Smile and Learn/Customization/Default/Contents/Thumbnails/"
    let  webgl_url = "https://assets.webgl.smileandlearn.com/"
    let  video_url = "https://assets.smileandlearn.com/Smile and Learn/AssetBundles/videos/"
    return {
      lang: 'es',
      smilecontents:[
        {
          id:1,
          section_title:{
            es:"Lecturas A1",
            en:"A1 Reading",
            pt:"Leituras A1",
          },
          contents:[
            {
              id:"LITERACYDEVELOPMENTAPPRENTICE12",
              title:{
                es:"Renata la gata",
                en:"Mitty the Kitty",
                pt:"Renata, a gata"
              },
              type: 'app',
              thumbnail: thumb_url+"LITERACYDEVELOPMENTAPPRENTICE12.png",
              url:{
                es: webgl_url+"LITERACYDEVELOPMENTAPPRENTICE12/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"LITERACYDEVELOPMENTAPPRENTICE12/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"LITERACYDEVELOPMENTAPPRENTICE12/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
            {
              id:"LITERACYDEVELOPMENTAPPRENTICE20",
              title:{
                es:"La princesa Teresa",
                en:"Princess Ines",
                pt:"A Princesa Teresa"
              },
              type: 'app',
              thumbnail: thumb_url+"LITERACYDEVELOPMENTAPPRENTICE20.png",
              url:{
                es: webgl_url+"LITERACYDEVELOPMENTAPPRENTICE20/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"LITERACYDEVELOPMENTAPPRENTICE20/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"LITERACYDEVELOPMENTAPPRENTICE20/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
            {
              id:"LITERACYDEVELOPMENTAPPRENTICE18",
              title:{
                es:"María y el cole",
                en:"Maria's Morning",
                pt:"Maria e a escola"
              },
              type: 'app',
              thumbnail: thumb_url+"LITERACYDEVELOPMENTAPPRENTICE18.png",
              url:{
                es: webgl_url+"LITERACYDEVELOPMENTAPPRENTICE18/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"LITERACYDEVELOPMENTAPPRENTICE18/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"LITERACYDEVELOPMENTAPPRENTICE18/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
            {
              id:"LITERACYDEVELOPMENTAPPRENTICE3",
              title:{
                es:"Alejo el bichejo",
                en:"Luke and Tug",
                pt:"Beto, o inseto"
              },
              type: 'app',
              thumbnail: thumb_url+"LITERACYDEVELOPMENTAPPRENTICE3.png",
              url:{
                es: webgl_url+"LITERACYDEVELOPMENTAPPRENTICE3/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"LITERACYDEVELOPMENTAPPRENTICE3/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"LITERACYDEVELOPMENTAPPRENTICE3/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
          ]
        },
        {
          id:2,
          section_title:{
            es:"Lecturas A2",
            en:"A2 Reading",
            pt:"Leituras A2",
          },
          contents:[
            {
              id:"LITERACYDEVELOPMENTINTERMEDIATE8",
              title:{
                es:"El payaso gracioso",
                en:"The Funny Clown",
                pt:"O palhaço engraçado"
              },
              type: 'app',
              thumbnail: thumb_url+"LITERACYDEVELOPMENTINTERMEDIATE8.png",
              url:{
                es: webgl_url+"LITERACYDEVELOPMENTINTERMEDIATE8/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"LITERACYDEVELOPMENTINTERMEDIATE8/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"LITERACYDEVELOPMENTINTERMEDIATE8/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
            {
              id:"LITERACYDEVELOPMENTINTERMEDIATE5",
              title:{
                es:"¡Vamos a casa de la abuela!",
                en:"Let's Go to Grammy's!",
                pt:"Vamos à casa da avó!"
              },
              type: 'app',
              thumbnail: thumb_url+"LITERACYDEVELOPMENTINTERMEDIATE5.png",
              url:{
                es: webgl_url+"LITERACYDEVELOPMENTINTERMEDIATE5/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"LITERACYDEVELOPMENTINTERMEDIATE5/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"LITERACYDEVELOPMENTINTERMEDIATE5/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
            {
              id:"LITERACYDEVELOPMENTADVANCED4",
              title:{
                es:"El pirata Rotapata",
                en:"Peg Leg Sam",
                pt:"O pirata Quebrapata"
              },
              type: 'app',
              thumbnail: thumb_url+"LITERACYDEVELOPMENTADVANCED4.png",
              url:{
                es: webgl_url+"LITERACYDEVELOPMENTADVANCED4/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"LITERACYDEVELOPMENTADVANCED4/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"LITERACYDEVELOPMENTADVANCED4/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
            {
              id:"LITERACYDEVELOPMENTADVANCED1",
              title:{
                es:"Dinosaurios",
                en:"Dinosaurs",
                pt:"Dinossauros"
              },
              type: 'app',
              thumbnail: thumb_url+"LITERACYDEVELOPMENTADVANCED1.png",
              url:{
                es: webgl_url+"LITERACYDEVELOPMENTADVANCED1/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"LITERACYDEVELOPMENTADVANCED1/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"LITERACYDEVELOPMENTADVANCED1/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
          ]
        },
        {
          id:3,
          section_title:{
            es:"Lecturas B1",
            en:"B1 Reading",
            pt:"Leituras B1",
          },
          contents:[
            {
              id:"LITERACYDEVELOPMENTADVANCED3",
              title:{
                es:"Kentrosaurus",
                en:"Kentrosaurus",
                pt:"Kentrosaurus"
              },
              type: 'app',
              thumbnail: thumb_url+"LITERACYDEVELOPMENTADVANCED3.png",
              url:{
                es: webgl_url+"LITERACYDEVELOPMENTADVANCED3/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"LITERACYDEVELOPMENTADVANCED3/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"LITERACYDEVELOPMENTADVANCED3/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
            {
              id:"READCOMPREHENSIONAPPRENTICE1",
              title:{
                es:"Jennifer López",
                en:"Jennifer Lopez",
                pt:"Jennifer Lopez"
              },
              type: 'app',
              thumbnail: thumb_url+"READCOMPREHENSIONAPPRENTICE10.png",
              url:{
                es: webgl_url+"READCOMPREHENSIONAPPRENTICE1/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"READCOMPREHENSIONAPPRENTICE1/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"READCOMPREHENSIONAPPRENTICE1/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
            {
              id:"READINGTRIVIAINTERMEDIATE1",
              title:{
                es:"¿Qué es el Waste Shark?",
                en:"The Waste Shark",
                pt:"O que é o Waste Shark?"
              },
              type: 'app',
              thumbnail: thumb_url+"READINGTRIVIAINTERMEDIATE1.png",
              url:{
                es: webgl_url+"READINGTRIVIAINTERMEDIATE1/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"READINGTRIVIAINTERMEDIATE1/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"READINGTRIVIAINTERMEDIATE1/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
            {
              id:"READINGNATUREINTERMEDIATE5",
              title:{
                es:"El cocodrilo de agua salada",
                en:"The Saltwater Crocodile",
                pt:"O crocodilo de água salgada"
              },
              type: 'app',
              thumbnail: thumb_url+"READINGNATUREINTERMEDIATE5.png",
              url:{
                es: webgl_url+"READINGNATUREINTERMEDIATE5/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"READINGNATUREINTERMEDIATE5/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"READINGNATUREINTERMEDIATE5/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
          ]
        },
        {
          id:4,
          section_title:{
            es:"Lecturas B2",
            en:"B2 Reading",
            pt:"Leituras B2",
          },
          contents:[
            {
              id:"READINGNATUREADVANCED4",
              title:{
                es:"Los microbios de nuestra piel",
                en:"Microbes on Your Skin",
                pt:"Os micróbios da nossa pele"
              },
              type: 'app',
              thumbnail: thumb_url+"READINGNATUREADVANCED4.png",
              url:{
                es: webgl_url+"READINGNATUREADVANCED4/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"READINGNATUREADVANCED4/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"READINGNATUREADVANCED4/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
            {
              id:"READINGNATUREMASTER5",
              title:{
                es:"El gran tiburón blanco",
                en:"The Great White Shark",
                pt:"O grande tubarão-branco"
              },
              type: 'app',
              thumbnail: thumb_url+"READINGNATUREMASTER5.png",
              url:{
                es: webgl_url+"READINGNATUREMASTER5/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"READINGNATUREMASTER5/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"READINGNATUREMASTER5/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
            {
              id:"READINGNATUREMASTER6",
              title:{
                es:"La Mosca Tse-Tsé",
                en:"The Tsetse Fly",
                pt:"A mosca tsé-tsé"
              },
              type: 'app',
              thumbnail: thumb_url+"READINGNATUREMASTER6.png",
              url:{
                es: webgl_url+"READINGNATUREMASTER6/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"READINGNATUREMASTER6/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"READINGNATUREMASTER6/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
            {
              id:"READINGTRIVIAMASTER1",
              title:{
                es:"El bolígrafo mágico 3D",
                en:"3D Pen Magic",
                pt:"A Caneta Mágica 3D"
              },
              type: 'app',
              thumbnail: thumb_url+"READINGTRIVIAMASTER1.png",
              url:{
                es: webgl_url+"READINGTRIVIAMASTER1/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"READINGTRIVIAMASTER1/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"READINGTRIVIAMASTER1/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
          ]
        },
        {
          id:5,
          section_title:{
            es:"Vocabulario A1",
            en:"A1 Vocabulary",
            pt:"Vocabulário A1",
          },
          contents:[
            {
              id:"WATERANIMALS_VIDEO",
              title:{
                es:"Animales del mar",
                en:"Sea Animals",
                pt:"Animais do Mar"
              },
              type: 'app',
              thumbnail: thumb_url+"WATERANIMALS.png",
              url:{
                es: video_url+"WATERANIMALS_VIDEO_ES.mp4",
                en: video_url+"WATERANIMALS_VIDEO_EN.mp4",
                pt: video_url+"WATERANIMALS_VIDEO_PT.mp4",
              }
            },
            {
              id:"FARMANIMALS_VIDEO",
              title:{
                es:"Animales de granja",
                en:"Farm Animals",
                pt:"Animais da Fazenda"
              },
              type: 'app',
              thumbnail: thumb_url+"FARMANIMALS.png",
              url:{
                es: video_url+"FARMANIMALS_VIDEO_ES.mp4",
                en: video_url+"FARMANIMALS_VIDEO_EN.mp4",
                pt: video_url+"FARMANIMALS_VIDEO_PT.mp4",
              }
            },
            {
              id:"WILDANIMALS_VIDEO",
              title:{
                es:"Animales salvajes",
                en:"Wild Animals",
                pt:"Animais selvagens"
              },
              type: 'app',
              thumbnail: thumb_url+"WILDANIMALS.png",
              url:{
                es: video_url+"WILDANIMALS_VIDEO_ES.mp4",
                en: video_url+"WILDANIMALS_VIDEO_EN.mp4",
                pt: video_url+"WILDANIMALS_VIDEO_PT.mp4",
              }
            },
            {
              id:"VERBS1_VIDEO",
              title:{
                es:"Verbos: Episodio 1",
                en:"Verbs: Episode 1",
                pt:"Verbos: Episódio 1"
              },
              type: 'app',
              thumbnail: thumb_url+"VERBS1.png",
              url:{
                es: video_url+"VERBS1_VIDEO_ES.mp4",
                en: video_url+"VERBS1_VIDEO_EN.mp4",
                pt: video_url+"VERBS1_VIDEO_PT.mp4",
              }
            },
          ]
        },
        {
          id:6,
          section_title:{
            es:"Vocabulario A2",
            en:"A2 Vocabulary",
            pt:"Vocabulário A2",
          },
          contents:[
            {
              id:"CITY1_VIDEO",
              title:{
                es:"La ciudad 1",
                en:"The City 1",
                pt:"A Cidade 1"
              },
              type: 'app',
              thumbnail: thumb_url+"CITY1.png",
              url:{
                es: video_url+"CITY1_VIDEO_ES.mp4",
                en: video_url+"CITY1_VIDEO_EN.mp4",
                pt: video_url+"CITY1_VIDEO_PT.mp4",
              }
            },
            {
              id:"CITY2_VIDEO",
              title:{
                es:"La ciudad 2",
                en:"The City 2",
                pt:"A Cidade 2"
              },
              type: 'app',
              thumbnail: thumb_url+"CITY.png",
              url:{
                es: video_url+"CITY2_VIDEO_ES.mp4",
                en: video_url+"CITY2_VIDEO_EN.mp4",
                pt: video_url+"CITY2_VIDEO_PT.mp4",
              }
            },
            {
              id:"VOCABULARYTECHNOLOGY",
              title:{
                es:"Tecnología",
                en:"Technology",
                pt:"Tecnologia"
              },
              type: 'app',
              thumbnail: thumb_url+"VOCABULARYTECHNOLOGY.png",
              url:{
                es: webgl_url+"VOCABULARYTECHNOLOGY/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"VOCABULARYTECHNOLOGY/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"VOCABULARYTECHNOLOGY/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
            {
              id:"EMOTIONS_VIDEO",
              title:{
                es:"Emociones",
                en:"Emotions",
                pt:"Emoções"
              },
              type: 'app',
              thumbnail: thumb_url+"EMOTIONS.png",
              url:{
                es: video_url+"EMOTIONS_VIDEO_ES.mp4",
                en: video_url+"EMOTIONS_VIDEO_EN.mp4",
                pt: video_url+"EMOTIONS_VIDEO_PT.mp4",
              }
            },
          ]
        },
        {
          id:7,
          section_title:{
            es:"Vocabulario B1",
            en:"B1 Vocabulary",
            pt:"Vocabulário B1",
          },
          contents:[
            {
              id:"HUMANBODY",
              title:{
                es:"Las partes de tu cuerpo",
                en:"Parts of the Body",
                pt:"As partes do seu corpo"
              },
              type: 'app',
              thumbnail: thumb_url+"HUMANBODY.png",
              url:{
                es: webgl_url+"HUMANBODY/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"HUMANBODY/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"HUMANBODY/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
            {
              id:"TECHNOLOGY2_VIDEO",
              title:{
                es:"Tecnología 2",
                en:"Technology 2",
                pt:"Tecnologia 2"
              },
              type: 'app',
              thumbnail: thumb_url+"TECHNOLOGY2.png",
              url:{
                es: video_url+"TECHNOLOGY2_VIDEO_ES.mp4",
                en: video_url+"TECHNOLOGY2_VIDEO_EN.mp4",
                pt: video_url+"TECHNOLOGY2_VIDEO_PT.mp4",
              }
            },
            {
              id:"TOOLS1_VIDEO",
              title:{
                es:"Herramientas 1",
                en:"Tools 1",
                pt:"Ferramentas 1"
              },
              type: 'app',
              thumbnail: thumb_url+"TOOLS1.png",
              url:{
                es: video_url+"TOOLS1_VIDEO_ES.mp4",
                en: video_url+"TOOLS1_VIDEO_EN.mp4",
                pt: video_url+"TOOLS1_VIDEO_PT.mp4",
              }
            },
            {
              id:"TOOLS2_VIDEO",
              title:{
                es:"Herramientas 2",
                en:"Tools 2",
                pt:"Ferramentas 2"
              },
              type: 'app',
              thumbnail: thumb_url+"TOOLS2.png",
              url:{
                es: video_url+"TOOLS2_VIDEO_ES.mp4",
                en: video_url+"TOOLS2_VIDEO_EN.mp4",
                pt: video_url+"TOOLS2_VIDEO_PT.mp4",
              }
            },
          ]
        },
        {
          id:8,
          section_title:{
            es:"Vocabulario B2",
            en:"B2 Vocabulary",
            pt:"Vocabulário B2",
          },
          contents:[
            {
              id:"SYNONYMS07_ACTIVITY",
              title:{
                es:"Sinónimos: Verbos avanzado",
                en:"Synonyms Verbs - Advanced",
                pt:"Sinônimos: Verbos Avançado"
              },
              type: 'app',
              thumbnail: thumb_url+"SYNONYMS.png",
              url:{
                es: webgl_url+"SYNONYMS07_ACTIVITY/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"SYNONYMS07_ACTIVITY/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"SYNONYMS07_ACTIVITY/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
            {
              id:"SYNONYMS05_ACTIVITY",
              title:{
                es:"Sinónimos: Adjetivos avanzado",
                en:"Synonyms Adjectives - Advanced",
                pt:"Sinônimos: Adjetivos Avançado"
              },
              type: 'app',
              thumbnail: thumb_url+"SYNONYMS.png",
              url:{
                es: webgl_url+"SYNONYMS05_ACTIVITY/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"SYNONYMS05_ACTIVITY/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"SYNONYMS05_ACTIVITY/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
            {
              id:"ANTONYMS06_ACTIVITY",
              title:{
                es:"Antónimos Adjetivos Máster",
                en:"Antonyms Adjectives - Master",
                pt:"Antônimos Adjetivos Máster"
              },
              type: 'app',
              thumbnail: thumb_url+"ANTONYMS.png",
              url:{
                es: webgl_url+"ANTONYMS06_ACTIVITY/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"ANTONYMS06_ACTIVITY/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"ANTONYMS06_ACTIVITY/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
            {
              id:"ANTONYMS07_ACTIVITY",
              title:{
                es:"Antónimos Adjetivos Avanzado",
                en:"Antonyms Adjectives - Advanced",
                pt:"Antônimos Adjetivos Avanzado"
              },
              type: 'app',
              thumbnail: thumb_url+"ANTONYMS.png",
              url:{
                es: webgl_url+"ANTONYMS07_ACTIVITY/index.html?l4ngs=3xnRPq+vDpbU1JHP7sr1/w==",
                en: webgl_url+"ANTONYMS07_ACTIVITY/index.html?l4ngs=CZlnmzl+Or62ruyOYUzTeQ==",
                pt: webgl_url+"ANTONYMS07_ACTIVITY/index.html?l4ngs=pDTNcdpFukpjC6Xy/x5i0A==",
              }
            },
          ]
        }
      ]
    }
  },
  mutations: {
    changeLang (state, lang) {
      state.lang = lang
    }
  },
  getters: {
    smilecontents: state => state.smilecontents,
  }
})
const app = createApp(App);
app.use(store)
app.use(Antd).mount("#app");






